<!--
 * @Author: your name
 * @Date: 2021-04-09 16:08:11
 * @LastEditTime: 2021-04-15 21:05:54
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\integralok\index.vue
-->
<template>
  <div class="allmsgclass">
    <navigation />
    <van-swipe
      :initial-swipe="swipindex"
      class="my-swipe"
      :autoplay="5000"
      indicator-color="#F55613"
      @change="change"
    >
      <van-swipe-item v-for="(item, index) in integralarray" v-bind:key="index">
        <div class="jf">
          <div class="jf1">
            <img :src="usable_point > jifen || usable_point == jifen?item.img:item.img2" alt="noimg" />
          </div>
          <div class="jf2">{{ item.name }}</div>
          <div class="jf3">{{ item.value }}</div>
          <div class="jf4">
            <div>{{ item.intro }}</div>
            <div>{{ item.detailes }}</div>
          </div>
        </div>
      </van-swipe-item>
    </van-swipe>
    <button
      v-show="usable_point > jifen || usable_point == jifen"
      @click="duimeth"
      class="bton bt"
    >
      申请兑换
    </button>
    <button
      v-show="usable_point < jifen"
      @click="duimeth"
      class="bton"
      id="aaa"
    >
      未达成兑换条件，继续加油哦
    </button>
    <kefu ref="kefuid"></kefu>
  </div>
</template>
<script>
import kefu from "../../components/kefu/index";
export default {
  components: {  kefu },
  data() {
    return {
      noborder: "noborder",
      color: "white;",
      jifen: "50000000000000000", //物品积分
      swipindex: "0", //下标初始位置
      usable_point: "", //现有积分
      integralarray: [
        {
          img: require("../../assets/xun2.png"),
          img2: require("../../assets/xun2_2.png"),
          name: "不拘一格",
          value: "5000分，兑换价值50元流量充值卡",
          intro: "流量充值卡",
          detailes:
            "50元通用流量充值卡（联系客服兑换，兑换请说明移动/联通/电信）。",
          num: "5000",
        },
        {
          img: require("../../assets/xun3.png"),
          img2: require("../../assets/xun3_3.png"),
          name: "足智多谋",
          value: "10000分，兑换价值100元话费充值卡",
          intro: "话费充值卡",
          detailes:
            "100元通用话费充值卡（联系客服兑换，兑换请说明移动/联通/电信）。",
          num: "10000",
        },
        {
          img: require("../../assets/xun4.png"),
          img2: require("../../assets/xun4_4.png"),
          name: "火眼金睛",
          value: "兑换价值300元京东卡",
          intro: "京东购物卡",
          detailes: "300元京东购物卡（联系客服兑换）。",
          num: "30000",
        },
        {
          img: require("../../assets/xun1.png"),
          img2: require("../../assets/xun1_1.png"),
          name: "点石成金 超级猎手",
          value: "35000000分，兑换特斯拉一辆",
          intro: "特斯拉电动车",
          detailes: "价值40万的特斯拉电动车一辆（联系客服兑换）。",
          num: "35000000",
        },
      ],
    };
  },
  created() {
    this.getjifenmsg();
    this.swipindex = this.$route.query.lightupmsg; //下标
    this.jifen = this.integralarray[this.swipindex].num; //积分
  },
  methods: {
    change(index) {
      this.jifen = this.integralarray[index].num;
    },
    clickdui() {
      this.$refs.kefuid.showmeth();
    },
    duimeth() {
      if (!(this.usable_point < this.jifen)) {
        this.clickdui();
      }
    },
    getjifenmsg() {
      this.$http
        .post("/firm/v1/Userinfo/usablePoint", {
          reqType: "userinfo",
        })
        .then((res) => {
          this.usable_point = JSON.parse(res.data).data.usable_point;
        });
    },
  },
};
</script>
<style scoped>
.bt {
  margin-top: 0.5rem;
}
.my-swipe {
  padding-bottom: 1.5rem;
}
.jf {
  margin-top: 0.85rem;
}
.jf1 {
  text-align: center;
}
.jf1 img {
  width: 3.38rem;
  height: 4.32rem;
}
.jf2 {
  font-size: 0.6rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 0.6rem;
  margin-top: 0.71rem;
  margin-bottom: 0.26rem;
  text-align: center;
}
.jf3 {
  font-size: 0.28rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 0.4rem;
  text-align: center;
  margin-bottom: 0.32rem;
}
.jf4 {
  padding: 0 0.32rem;
  font-size: 0.28rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 0.4rem;
}
.allmsgclass {
  min-height: 100%;
  background-color: white;
}
.allmsgclass >>> .van-hairline--bottom::after {
  border-bottom-width: 0px;
}
button#aaa {
  background: #b3b3b3;
  color: #ffffff;
  margin-top: 0.5rem;
}
</style>
